import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import GuestGuard from 'components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';
import MainLayout from 'components/MainLayout';
import CallBack from 'pages/authentication/login/Callback';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('pages/authentication/Login')));

// General pages
const ConsentRequests = Loadable(lazy(() => import('pages/consent/ConsentRequests')));
const ConsentDetailsPage = Loadable(lazy(() => import('pages/consent/ConsentDetailsPage')));
const BulkConsentRequests = Loadable(lazy(() => import('pages/consent/bulk/BulkConsentRequests')));
const BulkConsentCreator = Loadable(lazy(() => import('pages/consent/bulk/BulkConsentCreator')));
const BulkConsentDetails = Loadable(lazy(() => import('pages/consent/bulk/BulkConsentDetails')));

const IdVerificationRequestList = Loadable(lazy(() => import('pages/verifications/id-verification/IdVerificationRequests')));
const BulkIdVerifications = Loadable(lazy(() => import('pages/verifications/id-verification/bulk/BulkIdVerifications')));
const BulkIdVerificationCreator = Loadable(lazy(() => import('pages/verifications/id-verification/bulk/BulkIdVerificationCreator')));
const BulkIdVerificationDetails = Loadable(lazy(() => import('pages/verifications/id-verification/bulk/BulkIdVerificationDetails')));

const PhoneNumberVerificationRequests = Loadable(lazy(() => import('pages/verifications/phone-number-verification/PhoneNumberVerificationRequests')));
const BulkPhoneNumberVerifications = Loadable(lazy(() => import('pages/verifications/phone-number-verification/bulk/BulkPhoneNumberVerifications')));
const BulkPhoneNumberVerificationCreator = Loadable(lazy(() => import('pages/verifications/phone-number-verification/bulk/BulkPhoneNumberVerificationCreator')));
const BulkPhoneNumberVerificationDetails = Loadable(lazy(() => import('pages/verifications/phone-number-verification/bulk/BulkPhoneNumberVerificationDetails')));

const TinVerificationRequests = Loadable(lazy(() => import('pages/verifications/tin-verification/TinVerificationRequests')));

const AmlScreeningRequests = Loadable(lazy(() => import('pages/verifications/aml-screening/AmlScreenings')));
const BulkAmlScreeningRequests = Loadable(lazy(() => import('pages/verifications/aml-screening/bulk/BulkAmlScreenings')));
const BulkAmlScreeningDetails = Loadable(lazy(() => import('pages/verifications/aml-screening/bulk/BulkAmlScreeningDetails')));

const BusinessSearchRequests = Loadable(lazy(() => import('pages/verifications/company-search/CompanySearchRequests')));
const BulkBusinessSearchRequests = Loadable(lazy(() => import('pages/verifications/company-search/bulk/BulkCompanySearchRequests')));
const BulkBusinessSearchDetails = Loadable(lazy(() => import('pages/verifications/company-search/bulk/BulkCompanySearchDetails')));


const TemplateList = Loadable(lazy(() => import('pages/consent/templates/Templates')));
const TemplateCreator = Loadable(lazy(() => import('pages/consent/templates/create/TemplateCreator')));

const DataCustodianList = Loadable(lazy(() => import('pages/consent/data-custodians/DataCustodians')));

// Customer pages
const PeopleList = Loadable(lazy(() => import('pages/customers/people/People')));
const PeopleUpload = Loadable(lazy(() => import('pages/customers/people/UploadPeople')));
const CompaniesList = Loadable(lazy(() => import('pages/customers/companies/Companies')));
const CustomerDetails = Loadable(lazy(() => import('pages/customers/common-components/customer-profile/CustomerDetails')));

// Admin pages
const PartnersList = Loadable(lazy(() => import('pages/management/partners/Partners')));
const PriceBandsList = Loadable(lazy(() => import('pages/management/price-bands/PriceBands')));
const StaffList = Loadable(lazy(() => import('pages/management/staff/Staff')));
const StaffDetails = Loadable(lazy(() => import('pages/management/staff/details/StaffDetails')));
const Settings = Loadable(lazy(() => import('pages/management/settings/Settings')));
const Invoices = Loadable(lazy(() => import('pages/management/invoices/Invoices')));

// Account pages
const Profile = Loadable(lazy(() => import('pages/account/Profile')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('pages/NotFound')));
const ServerError = Loadable(lazy(() => import('pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'login-callback',
        element: <CallBack />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <PeopleList />
      },
      {
        path: 'people',
        children: [
          {
            path: '',
            element: <PeopleList />
          },
          {
            path: ':customerId',
            element: <CustomerDetails />
          },
          {
            path: 'upload',
            element: <PeopleUpload />
          },
        ]
      },
      {
        path: 'companies',
        children: [
          {
            path: '',
            element: <CompaniesList />
          },
          {
            path: ':customerId',
            element: <CustomerDetails />
          }
        ]
      },
      {
        path: 'consent-requests',
        children: [
          {
            path: '',
            element: <ConsentRequests />
          },
          {
            path: ':consentId',
            element: <ConsentDetailsPage />
          }
        ]
      },
      {
        path: 'bulk-consent-requests',
        children: [
          {
            path: '',
            element: <BulkConsentRequests />
          },
          {
            path: 'create',
            element: <BulkConsentCreator />
          },
          {
            path: ':bulkId',
            element: <BulkConsentDetails />
          }
        ]
      },
      {
        path: 'templates',
        children: [
          {
            path: '',
            element: <TemplateList />
          },
          {
            path: 'create',
            element: <TemplateCreator />
          },
        ]
      },
      {
        path: 'templates/:templateId',
        element: <TemplateCreator />
      },
      {
        path: 'data-custodians',
        children: [
          {
            path: '',
            element: <DataCustodianList />
          },
        ]
      },
      {
        path: 'verify-national-id-data',
        children: [
          {
            path: '',
            element: <IdVerificationRequestList />
          }
        ]
      },
      {
        path: 'bulk-verify-national-id-data',
        children: [
          {
            path: '',
            element: <BulkIdVerifications />
          },
          {
            path: 'create',
            element: <BulkIdVerificationCreator />
          },
          {
            path: ':bulkId',
            element: <BulkIdVerificationDetails />
          }
        ]
      },
      {
        path: 'verify-phone-number',
        children: [
          {
            path: '',
            element: <PhoneNumberVerificationRequests />
          }
        ]
      },
      {
        path: 'bulk-verify-phone-number',
        children: [
          {
            path: '',
            element: <BulkPhoneNumberVerifications />
          },
          {
            path: 'create',
            element: <BulkPhoneNumberVerificationCreator />
          },
          {
            path: ':bulkId',
            element: <BulkPhoneNumberVerificationDetails />
          }
        ]
      },
      {
        path: 'verify-tin',
        children: [
          {
            path: '',
            element: <TinVerificationRequests />
          }
        ]
      },
      {
        path: 'aml-screening',
        children: [
          {
            path: '',
            element: <AmlScreeningRequests />
          }
        ]
      },
      {
        path: 'bulk-aml-screening',
        children: [
          {
            path: '',
            element: <BulkAmlScreeningRequests />
          },
          {
            path: ':bulkId',
            element: <BulkAmlScreeningDetails />
          }
        ]
      },
      {
        path: 'business-search',
        children: [
          {
            path: '',
            element: <BusinessSearchRequests />
          }
        ]
      },
      {
        path: 'bulk-business-search',
        children: [
          {
            path: '',
            element: <BulkBusinessSearchRequests />
          },
          {
            path: ':bulkId',
            element: <BulkBusinessSearchDetails />
          }
        ]
      },
      {
        path: 'staff',
        children: [
          {
            path: '',
            element: <StaffList />
          },
          {
            path: ':staffId',
            element: <StaffDetails />
          }
        ]
      },
      {
        path: 'partners/:partnerId',
        element: <Settings />
      },
      {
        path: 'partners',
        element: <PartnersList />
      },
      {
        path: 'price-bands',
        element: <PriceBandsList />
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: <Settings />
          },
          {
            path: ':partnerId',
            element: <Settings />
          }
        ]
      },
      {
        path: 'invoices',
        element: <Invoices />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Profile />
          }
        ]
      },
    ]
  },
  {
    path: 'error',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
