import { getAnalytics } from 'firebase/analytics';
import { getRemoteConfig, getValue } from 'firebase/remote-config';
import { initializeApp } from 'firebase/app';


export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000;

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

export const appSettings = {
  appName: 'Verify'
};

remoteConfig.defaultConfig = {
  'enable_keycloak': false,
  'enable_new_billing': false,
  'enable_tin_verification': false,
  'enable_aml_check': false,
  'enable_business_search': false,
  'enable_new_company_creation': false,
  'enable_new_person_creation': false,
};

export const remoteConfigData = {
  enableKeycloak: () => {
    return getValue(remoteConfig, 'enable_keycloak').asBoolean();
  },
  enableNewBilling: () => {
    return getValue(remoteConfig, 'enable_new_billing').asBoolean();
  },
  enableTinVerification: () => {
    return getValue(remoteConfig, 'enable_tin_verification').asBoolean();
  },
  enableAmlCheck: () => {
    return getValue(remoteConfig, 'enable_aml_check').asBoolean();
  },
  enableBusinessSearch: () => {
    return getValue(remoteConfig, 'enable_business_search').asBoolean();
  },
  enableNewCompanyCreation: () => {
    return getValue(remoteConfig, 'enable_new_company_creation').asBoolean();
  },
  enableNewPersonCreation: () => {
    return getValue(remoteConfig, 'enable_new_person_creation').asBoolean();
  }
};
