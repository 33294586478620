import { FC } from 'react';
import {
  Box,
  Button,
} from '@mui/material';

interface ContactUsProps {
  title: string;
}

const ContactUsButton: FC<React.PropsWithChildren<ContactUsProps>> = (props) => {
  const handleClick = (): void => {
    window.location.href = 'mailto:support@iotec.io?subject=ID Verification Web Portal Support';
  };

  return (
    <>
      <Button
        onClick={handleClick}
        sx={{
          color: 'main.primary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          pl: '16px',
          pr: '8px',
          py: '12px',
          textAlign: 'left',
          textTransform: 'none',
          width: '100%'
        }}
        variant="text"
      >
        <Box sx={{ flexGrow: 1 }}>
          {props.title}
        </Box>
      </Button>
    </>
  );
};

export default ContactUsButton;
