import { isEmpty } from 'lodash';
import { hasNoValue, hasValue } from '../components/inputs/inputHelpers';

export const getInitials = (fullName = '') => {
  try {
    return fullName.split(' ').map((it) => it[0].toUpperCase()).join('');
  } catch (e) {
    return '';
  }
};

export const dataStrToArray = (str: string | string[]) => {
  if (!str || isEmpty(str)) return [];
  try {
    if (typeof str === 'string') return [str];
    return str;
  } catch (e) {
    return [];
  }
};

export function getRandomStr(max = 16) {
  const letters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let str = '';
  for (let i = 0; i < max; i++) {
    str += letters[Math.floor(Math.random() * max)];
  }
  return str;
}

export function toPascalCase(data: string) {
  return data.match(/[a-z]+/gi)
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
}

export function pascalToTitleCase(str: string) {
  return str
  // Look for long acronyms and filter out the last letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
  // Look for lower-case letters followed by upper-case letters
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
  // Look for lower-case letters followed by numbers
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, (newStr) => newStr.toUpperCase())
  // Remove any white space left around the word
    .trim();
}

export const trimString = (data: string, len: number = 20, append = '') => {
  if (hasNoValue(data)) return '';
  if (data.length <= len) return data;
  return data.substring(0, len) + append;
};

export const trimGuid = (data: string, len: number = 10) => data.length > len ? `${data.substring(0, len)}...` : data.substr(0, len) ;

export function enumToArray(typeData: any) {
  return Object.keys(typeData);
}

// Split pascal case
export function camelPad(str: string) {
  return str
  // Look for long acronyms and filter out the last letter
    .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
  // Look for lower-case letters followed by upper-case letters
    .replace(/([a-z\d])([A-Z])/g, '$1 $2')
  // Look for lower-case letters followed by numbers
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
    .replace(/^./, (newStr) => newStr.toUpperCase())
  // Remove any white space left around the word
    .trim();
}

export function titleCase(str?: string) {
  return str?.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1, txt.length));
}

export function toTitleCase(str: string) {
  if (!hasValue(str)) {
    return str;
  }
  const splitStr = str.toLowerCase()
    .split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0)
      .toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
