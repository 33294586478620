import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { Autocomplete, CircularProgress, createFilterOptions, TextField } from '@mui/material';

import { search } from '../../utils/ajax';
import { hasNoValue, hasValue } from '../inputs/inputHelpers';
import { ComboValue, PComboProps } from './PComboInput';
import { Option } from '../inputs/option';
import { useTheme } from '@mui/material/styles';


const filter = createFilterOptions<Option | string>();

const FakeProgress = () => <div style={{ height: 20, width: 20 }}>&nbsp;</div>;

const filterOptions = (options: (Option | string)[], params: any) => {
  const filtered = filter(options, params) as Option[];
  // Suggest the creation of a new value
  if (params.inputValue !== '') {
    filtered.push({
      id: params.inputValue,
      name: `Create "${params.inputValue}"`,
    });
  }
  return filtered;
};

export interface IPRemoteProps extends Omit<PComboProps, 'options'> {
  remote: string
  filter?: any
  searchOnline?: boolean
  parser?: (d: any) => Option | string
  defaultOptions?: Option[] | string[]
}

export function PRemoteSelect(props: IPRemoteProps) {
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<Option[] | string[]>(props.defaultOptions || []);
  const [query, setQuery] = React.useState<string>('');
  const [inputValue, setInputValue] = React.useState('');

  const [dataCache, setDataCache] = React.useState<any>({});

  function hashCode(str: string): number {
    // eslint-disable-next-line no-bitwise
    return str.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
  }

  const isInCache = useCallback((f: any) => {
    const key = hashCode(JSON.stringify(f));
    if (dataCache[key]) {
      return dataCache[key];
    }
    return false;
  }, [dataCache]);

  const addToCache = useCallback((f: any, resp: any) => {
    const key = hashCode(JSON.stringify(f));
    const newData = { ...dataCache, [key]: resp };
    setDataCache(newData);
  }, [dataCache]);

  const fetch = useCallback((queryString: string) => {
    console.log('Fetching data...', props.remote);
    if (hasNoValue(props.remote)) {
      return;
    }
    const newFilter = { ...props.filter, queryString, limit: 1000 };
    const cached = isInCache(newFilter);
    if (cached) {
      setOptions(cached);
      return;
    }
    setLoading(true);
    search(props.remote, newFilter,
      (resp = []) => {
        const results = hasValue(resp) ? resp : [];
        if (props.parser) {
          const data = results.map(props.parser);
          setOptions(data);
          addToCache(newFilter, data);
        } else {
          setOptions(results);
          addToCache(newFilter, results);
        }
      },
      undefined,
      () => {
        setLoading(false);
      });
  }, [props.parser, props.filter, props.remote, isInCache, addToCache]);

  useEffect(() => {
    fetch(query);
  }, [fetch, query]);

  const handleTouched = () => {
    if (props.onBlur)props.onBlur();
  };

  function handleChange(
    event: ChangeEvent<{}>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value: ComboValue, _: any
  ) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onChange(value);
  }

  function getOptionLabel(o: string | Option) {
    if (typeof o === 'string') {
      return o;
    }
    if (typeof o === 'object') {
      const obj = o as Option;
      return obj?.name;
    }
    return '';
  }

  const {
    onChange, onBlur,
    value, label,
    variant,
    multiple,
    helperText,
    showError,
    parser: i,
    defaultOptions,
    searchOnline,
    margin = 'dense',
    freeSolo,
    textFieldProps, defaultValue,
    ...autoProps
  } = props;
  // TODO fix this type
  const cleanValue: any = value || (multiple ? [] : null);
  const isFreeSolo = props.searchOnline ? (x) => x : freeSolo;
  const filterOpts = isFreeSolo ? filterOptions : undefined;
  return (
    <Autocomplete
      {...autoProps}
      componentsProps={{
        paper: {
          style: {
            boxShadow: theme.shadows[10],
            borderRadius: theme.shape.borderRadius,
          }
        }
      }}
      value={cleanValue}
      multiple={multiple}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (searchOnline) {
          setQuery(newInputValue);
        }
      }}
      isOptionEqualToValue={(o:any, v:any) => o === v || o.id === v || o.id === v || o.id === v.id}
      getOptionLabel={getOptionLabel}
      // getOptionSelected={getOptionSelection}
      filterOptions={filterOpts}
      options={options}
      autoComplete
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          margin={margin}
          label={label}
          fullWidth
          onBlur={handleTouched}
          error={showError}
          helperText={showError && helperText}
          variant={props.variant}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : <FakeProgress />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
